import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const catalogGatewayApi = (api) => {

    const searchFilter = ({ data, clientId }) => {
        return api.post(`filter/v4/facets`, data, {
            headers: {
                ...(clientId && { clientId })
            }
        });
    };

    const searchFilterDesktop = ({ data, clientId }) => {
        return api.post(`filter/v3/facets`, data, {
            headers: {
                ...(clientId && { clientId })
            }
        });
    };

    const getCarlist = ({ data, url, clientId, userId }) => {
        return api.post(`listing/v1/${url || "buy-used-car"}`, data, {
            headers: {
                ...(clientId && { clientId }),
                ...(userId && { userId })
            }
        });
    };

    const getBestMatches = ({ params, token, clientId }) => {
        return api.get(`car-match/v1`, {
            params,
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                ...(clientId && { clientId })
            }
        });
    };

    const updateBestMatches = ({ data, token, clientId }) => {
        return api.put(`car-match/v1/WISHLIST`, data, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                ...(clientId && { clientId })
            }
        });
    };

    const getSearchSuggestions = (text) => {
        return api.get(`suggestion/v1?text=${text}`);
    };

    const getNearestcities = (url) => {
        return api.get(`seo/v1/nearby-cities${url}`, {});
    };

    const getPresetFilters = (params) => {
        return api.get(`filter/v1/preset-filters`, { params });
    };

    const getCarDetails = (appointmentId, extraParams = {}, token) => {
        return api.post("detail/v1/", { appointmentId, ...extraParams }, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` })
            }
        });
    };

    const getOtherCars = (appointmentId, params = {}) => {
        return api.get(`listing/v1/similar-cars/${appointmentId}`, {
            params
        });
    };

    const fetchMoreCarsForYou = (appointmentId) => {
        return api.get(`detail/v1/similar-banner-seo/${appointmentId}`);
    };

    const getRecentlyViewedCars = ({ params, clientId }) => {
        return api.get(`recently-viewed-cars/v1/`, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                ...(clientId && { clientId })
            },
            params
        });
    };
    const fetchHomePageBanners = (params) => {
        return api.get("banner/v1?=", { params });
    };

    const postOemServiceHstoryNotify = (appId, token) => {
        return api.post(`oem-service-history/v1/${appId}/notify`, {}, {
            headers: {
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "IN",
                Authorization: `Bearer ${token}`
            }
        });
    };

    const getNearByCarlist = ({ data, url, clientId, userId }, source) => {
        return api.post(`listing/v1/nearby-city/${url || "buy-used-car"}`, data, {
            headers: {
                ...(clientId && { clientId }),
                ...(userId && { userId }),
                ...(source && { source })
            }
        });
    };
    const getServiceability = (cityId, source) => {
        return api.get(`listing/v1/serviceability?cityId=${cityId}&limit=0`, {
            headers: {
                ...(source && { source })
            }
        });
    };
    const getRecommendedCars = ({ params, clientId, cityId }) => {
        return api.get(`listing/v1/recommended-cars?cityId=${cityId}`, {
            headers: {
                ...(clientId && { clientId })
            },
            params
        });
    };

    const fetchWishlistedCarsData = ({ userId, clientId, params }) => {
        return api.get(`listing/v1/cars`, {
            params,
            headers: {
                ...(clientId && { clientId }),
                ...(userId && { userId })
            }
        });
    };

    const getCarDetailsVersion2 = (appointmentId, extraParams = {}, token) => {
        return api.post("detail/v2/", { appointmentId, ...extraParams }, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` })
            }
        });
    };
    const getCarComparisonData = ({ appointmentIds, ...params }) => {
        return api.get(`compare/v1?appIds=${appointmentIds}`, {
            params
        });
    };

    const fetchBuyUsedCarModelsByBrands = () => {
        return api.get(`filter/v1/top-make-model`);
    };

    const homePageMergedData = ({ params, clientId }) => {
        return api.get("homepage/v1/merged/web", {
            headers: {
                ...(clientId && { clientId })
            },
            params
        });
    };
    const getOemServiceHistory = (appId) => {
        return api.get(`oem-service-history/v1/${appId}`);
    };
    const fetchCarDetailsByCarId = (carId, params) => {
        return api.get(`listing/v1/cars?appIds=${carId}`, {
            ...(params && { params })
        });

    };

    const getSimilarCarsForListingCars = (carId, { data, clientId, userId }) => {
        return api.post(`similar-cars/v1/${carId}`, data, {
            headers: {
                ...(clientId && { clientId }),
                ...(userId && { userId })
            }
        });
    };

    const getPopularCityListAndBrands = () => {
        return api.get("website/v1/cities", {});
    };
    const fetchFooterLinks = () => {
        return api.get("/website/v1/page/footer");
    };

    const fetchMobileFooterLinks = () => {
        return api.get("/website/v1/page/footer");
    };

    const getCarListingSeoContent = ({ url }) => {
        return api.get(`/seo/v1/content/${url === "category-page" ? "buy-used-car" : url}`);
    };

    const fetchOtherHubsInCity = (cityId) => {
        return api.get(`hub/v1/${cityId}`);
    };

    const fetchCategoryPageData = (params, token, section) => {
        const categorySection = section ? `?sectionKeys=${section}` : "";
        return api.post(
            `/homepage/v1/category-page${categorySection}`, params,
            {
                headers: {
                    ...(token && { Authorization: `Bearer ${token}` })
                }
            }
        );
    };
    const fetchWishlistedCarsIds = ({ userId, params, clientId, token }) => {
        return api.get(`/wishlist/v1`, {
            headers: {
                mediaSource: "HELLO_AR",
                ...(userId && { userId }),
                ...(clientId && { clientId }),
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const addToWishlist = (data, params, token) => {
        return api.put(`/wishlist/v1`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });
    };

    const fetchHeaderBuyCars = (cityId) => {
        return api.get(`/menu/hamburger/v1/${cityId}`);
    };
    const getCategoryPageTemplatesData = (params, token, section = "") => {
        const categorySection = section ? `?pageNames=${section}` : "";
        return api.post(
            `/homepage/v2/category-page${categorySection}`, params,
            {
                headers: {
                    ...(token && { Authorization: `Bearer ${token}` })
                }
            }
        );
    };

    const getFilterGuide = () => {
        return api.get(`filter/v4/meta`);
    };

    return {
        searchFilter,
        getSearchSuggestions,
        getPresetFilters,
        getCarlist,
        getNearestcities,
        getOtherCars,
        getCarDetails,
        fetchMoreCarsForYou,
        getRecentlyViewedCars,
        fetchHomePageBanners,
        postOemServiceHstoryNotify,
        fetchWishlistedCarsData,
        getRecommendedCars,
        getCarComparisonData,
        getServiceability,
        getNearByCarlist,
        getCarDetailsVersion2,
        fetchBuyUsedCarModelsByBrands,
        homePageMergedData,
        getOemServiceHistory,
        fetchCarDetailsByCarId,
        getSimilarCarsForListingCars,
        getPopularCityListAndBrands,
        fetchFooterLinks,
        fetchMobileFooterLinks,
        getCarListingSeoContent,
        fetchOtherHubsInCity,
        getBestMatches,
        updateBestMatches,
        fetchCategoryPageData,
        fetchWishlistedCarsIds,
        addToWishlist,
        fetchHeaderBuyCars,
        getCategoryPageTemplatesData,
        getFilterGuide,
        searchFilterDesktop
    };
};

export const CatalogGatewayService = catalogGatewayApi(middlewareConfig(MIDDLEWARE_ENUMS.CATALOG_GATEWAY));
