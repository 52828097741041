import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const omsAggregatorApi = (api) => {
    const checkOrderDetail = (params, appointmentId, token) => {
        return api.get(`/order/checkout-flow/${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v3"
        });
    };

    const getOrderSummary = (orderId, token) => {
        return api.get(`/order/${orderId}/checkout-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const createFasterOrder = (payload, token, source) => {
        return api.post(`/order/checkout/faster/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    const getFasterOrder = (orderId, token, params) => {
        return api.get(`/order/${orderId}/faster-checkout-data`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v3"
        });
    };

    const getBookingDetails = (token, orderId) => {
        return api.get(`order/${orderId}/post-booking-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getBookingSummaryRevamp = (token, orderId) => {
        return api.get(`/order/${orderId}/post-delivery-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getDeliveryChecklistDetails = (token, orderId) => {
        return api.get(`order/${orderId}/delivery-details`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getRcDelayReasons = (token, orderId) => {
        return api.get(`/order/${orderId}/post-delivery/rc/delay-reason`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getDeliveryChecklistOTP = (token, orderId) => {
        return api.get(`order/${orderId}/delivery/otp`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const setDeliveryChecklistItems = (token, orderId, payload) => {
        return api.put(`order/${orderId}/delivery-details`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getPickupLocations = ({ appointmentId, pincode, token, source, clientId }) => {
        return api.get(`/appointmentId/${appointmentId}/ci-location?pincode=${pincode}`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source }),
                clientId
            },
            version: "v3"
        });
    };

    const getDeliveryChecklistDetailsV2 = (token, orderId) => {
        return api.get(`/delivery-flow-details/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const postDeliveryDetails = (token, orderId, payload) => {
        return api.post(`/delivery-flow-details/${orderId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getBookingData = (token, orderId) => {
        return api.get(`order/${orderId}/post-booking-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v11"
        });
    };

    const getBookedOrdersList = ({ token, source, clientId, pincode, userId }) => {
        return api.get(`/user/my-cars?myCarsCriteria=BOOKED_ORDERS`, {
            headers: {
                Authorization: `Bearer ${token}`,
                ...(source && { SOURCE: source }),
                clientId,
                pincode,
                userId,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"

            }
        });
    };

    const getOrderSummaryData = (token, orderId, params = null) => {
        return api.get(`order/${orderId}/payment-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v8"
        });
    };
    const mapClientId = (secureToken, clientId, payload) => {
        return api.put(`user/link`, payload, {
            headers: {
                Authorization: `Bearer ${secureToken}`,
                clientId,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v1"
        });
    };

    const getRmDetails = (token) => {
        return api.get(`user/my-rm`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v1"
        });
    };

    const getVasList = (token, orderId) => {
        return api.get(`vas/${orderId}/list`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v1"
        });
    };

    const addVas = (token, orderId, vasId) => {
        return api.post(`vas/${orderId}/${vasId}`, null, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const deleteVas = (token, orderId, vasId) => {
        return api.delete(`vas/${orderId}/${vasId}`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getRefundData = (token, orderId) => {
        return api.get(`order/${orderId}/refund`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };
    const getVasListV2 = (token, orderId, params) => {
        return api.get(`product-catalog/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v4"
        }
        );
    };

    const addVasV2 = (token, orderId, productId) => {
        return api.put(`product-catalog/${orderId}/${productId}`, {}, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const deleteVasV2 = (token, orderId, productId) => {
        return api.delete(`product-catalog/${orderId}/${productId}`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getVasFaqs = (token, type, params) => {
        return api.get(`product-catalog/faq/${type}`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const getVasNomineeDetails = (token, orderId) => {
        return api.get(`product-catalog/${orderId}/car-insurance/nominee`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const postVasNomineeDetails = (token, payload) => {
        return api.post(`product-catalog/car-insurance/nominee`, payload, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getInsuranceDetail = ({ token, orderId, params }) => {
        return api.get(`product-catalog/${orderId}/user-opted-product`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const getMyBookings = (token, pageType, params) => {
        return api.get(`/user/my-cars/${pageType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            params,
            version: "v2"
        });
    };

    const getOrderDetails = (token, orderId) => {
        return api.get(`order/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            }
        });
    };

    const getVasTestimonials = (token) => {
        return api.get(`order/testimonials`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            }
        });
    };

    const getVasFaqV2 = (token, type, params) => {
        return api.get(`product-catalog/faq/${type}`, {
            headers: {
                Authorization: `Bearer ${token} `,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v2"
        });
    };
    const getIdvs = (token, orderId, params) => {
        return api.get(`product-catalog/${orderId}/possible-idvs`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            params,
            version: "v3"
        });
    };

    const getPostCheckoutOffers = ({ orderId, token, payload }) => {
        return api.post(`/post-booking/cf/${orderId}/offers`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            version: "v2"
        });
    };

    const fetchCalculatedDIYOffer = ({ orderId, token, payload }) => {
        return api.post(`/post-booking/cf/${orderId}/offer/recalculate`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            version: "v2"
        });
    };

    const fetchCalculatedPostCheckoutOffer = ({ orderId, token, payload }) => {
        return api.post(`/post-booking/cf/${orderId}/offers/recalculate`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            version: "v2"
        });
    };

    const fetchBankingConsentDetails = (token) => {
        return api.get(`/banking-consent`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            }
        });
    };

    const getWarrantyData = (token, orderId) => {
        return api.get(`/warranty/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            }
        });
    };
    const getBuyback = ({ appointmentId, token, orderId }) => {
        return api.get(`/buyback/`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            params: {
                ...(appointmentId && { appointmentId }),
                ...(orderId && { orderId })
            }
        });
    };

    const getTestDriveDeliveryDetails = ({orderId, token, ...params}) => {
        return api.get(`/order/${orderId}/pre-checkout-details`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            params
        });
    };

    const getTestDriveConfig = ({token, ruleType}) => {
        return api.get(`/order/pre-checkout/config/${ruleType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            }
        });
    };

    return {
        checkOrderDetail,
        getOrderSummary,
        createFasterOrder,
        getFasterOrder,
        getBookingDetails,
        getBookingSummaryRevamp,
        getRcDelayReasons,
        getDeliveryChecklistDetails,
        getDeliveryChecklistOTP,
        setDeliveryChecklistItems,
        getPickupLocations,
        getBookedOrdersList,
        getDeliveryChecklistDetailsV2,
        postDeliveryDetails,
        getBookingData,
        getOrderSummaryData,
        mapClientId,
        getRmDetails,
        getVasList,
        addVas,
        deleteVas,
        getRefundData,
        getVasListV2,
        addVasV2,
        deleteVasV2,
        getVasFaqs,
        getVasNomineeDetails,
        postVasNomineeDetails,
        getInsuranceDetail,
        getMyBookings,
        getOrderDetails,
        fetchCalculatedDIYOffer,
        fetchCalculatedPostCheckoutOffer,
        getPostCheckoutOffers,
        getVasFaqV2,
        getIdvs,
        getVasTestimonials,
        fetchBankingConsentDetails,
        getBuyback,
        getTestDriveDeliveryDetails,
        getWarrantyData,
        getTestDriveConfig
    };
};

export const OmsAggregatorService = omsAggregatorApi(middlewareConfig(MIDDLEWARE_ENUMS.OMS_AGGREGATOR));

