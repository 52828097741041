/* eslint-disable new-cap */
import Types from "./types";
import { B2C_OMS_VARIANTS, HTTP_CODES } from "../../constants/app-constants";
import cleverTap from "../../tracking/clevertap";
import ConsumerFinanceService from "../../service/consumer-finance-service";
import { CheckoutService } from "../../service/checkout-service";
import { CarService } from "../../service/car-service";
import { UsedCarService } from "../../service/used-car-service";
import { OmsAggregatorService } from "../../service/oms-aggregator-service";
import { UserService } from "../../service/user-service";
import { AuthService } from "../../service/auth-service";
import { LeadServiceJava } from "../../service/lead-service-java-api";

const setName = (name) => ({
    type: Types.SET_NAME,
    name
});

const setProfileName = (profileName) => ({
    type: Types.SET_PROFILE_NAME,
    profileName
});

const setMobile = (mobile) => ({
    type: Types.SET_MOBILE,
    mobile
});

const setLastVerifiedMobile = (lastVerifiedMobile) => ({
    type: Types.SET_LAST_VERIFIED_MOBILE,
    lastVerifiedMobile
});

const setProfileMobile = (profileMobile) => ({
    type: Types.SET_PROFILE_MOBILE,
    profileMobile
});

const setEmail = (email) => ({
    type: Types.SET_EMAIL,
    email
});

const setCheckAuth = (checkAuth) => ({
    type: Types.CHECK_AUTH,
    checkAuth
});

// eslint-disable-next-line
const getProfileDetailsSuccess = ({ phone_number: mobile, user_name: name, user_email: email, user_id: userId, token, cibil_consent: cibilConsent }) => ({
    type: Types.GET_PROFILE_DETAILS_SUCCESS,
    name, mobile, email, userId, token, cibilConsent
});

const markUnauthorized = () => ({
    type: Types.MARK_UNAUTHORIZED
});

const getProfileDetailsFailure = () => ({
    type: Types.GET_PROFILE_DETAILS_FAILURE
});

const getProfileDetails = () => (dispatch, getStats) => {
    return new Promise((resolve, reject) => {
        AuthService.whoAmI()
            .then(response => {
                const { data: { data: { user } } } = response;
                dispatch(getProfileDetailsSuccess(user));
                dispatch(setCheckAuth(false));
                cleverTap.login({ user: getStats().user });
                resolve(response);
            })
            .catch(error => {
                if (error.status === HTTP_CODES.UNAUTHORIZED) {
                    dispatch(markUnauthorized());
                }
                dispatch(getProfileDetailsFailure());
                dispatch(setCheckAuth(false));
                reject(error);
            });
    });
};

const updateProfileInit = () => ({
    type: Types.UPDATE_PROFILE
});

const updateProfileSuccess = ({
    phone_number: mobile, user_name: name, user_email: email, user_id: userId, token, cibil_consent: cibilConsent }
) => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    name, mobile, email, userId, token, cibilConsent
});

const updateProfileFailure = () => ({
    type: Types.UPDATE_PROFILE_FAILURE
});

const updateProfile = (name = "", email = "", cibilConsent = "") => dispatch => {
    dispatch(updateProfileInit());
    return new Promise((resolve, reject) => {
        AuthService.updateProfile({
            user_name: name,
            user_email: email,
            ...(cibilConsent && { cibil_consent: cibilConsent })
        }).then(response => {
            const { data: { data: { user } } } = response;
            dispatch(updateProfileSuccess(user));
            resolve(response);
        }).catch(error => {
            dispatch(updateProfileFailure(error));
            reject(error);
        });
    });
};

const setFirstLoad = () => ({
    type: Types.SET_FIRST_LOAD
});

const updateLeadType = (leadType) => ({
    type: Types.UPDATE_LEAD_TYPE,
    leadType
});

const setSocketId = (socketId) => ({ type: Types.SET_SOCKET_ID, socketId });

const setTruecallerPromptCount = (trueCallerPromptCount) => ({
    type: Types.TRUECALLER_PROMPT_COUNT,
    trueCallerPromptCount
});

const updateWebviewStatus = (isWebview) => ({
    type: Types.UPDATE_WEBVIEW_STATUS,
    isWebview
});

const setGAId = (gaId) => ({
    type: Types.SET_GA_ID,
    gaId
});

const updateCurrentPageType = (currentPageType) => ({
    type: Types.UPDATE_CURRENT_PAGE_TYPE,
    currentPageType
});

const checkAuthToken = () => ({
    type: Types.CHECK_AUTH_TOKEN
});

const getUserAddressesSuccess = (data) => ({
    type: Types.GET_USER_ADDRESSES_SUCCESS,
    data
});

const getUserAddressesFailure = (error) => ({
    type: Types.GET_USER_ADDRESSES_FAILURE,
    error
});

const getUserAddresses = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        CheckoutService.getUserAddresses(token).then(response => {
            dispatch(getUserAddressesSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getUserAddressesFailure(error));
            reject(error);
        });
    });
};

const updateUserAddress = (token, id, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateUserAddress(token, id, payload).then(response => {
            // dispatch(getUserAddressesSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            // dispatch(getUserAddressesFailure(error));
            reject(error);
        });
    });
};

const addUserAddress = (token, payload) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.addUserAddress(token, payload).then(response => {
            // dispatch(getUserAddressesSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            // dispatch(getUserAddressesFailure(error));
            reject(error);
        });
    });
};

const getBookingCountSuccess = (bookingCount) => {
    return {
        type: Types.GET_BOOKING_COUNT,
        bookingCount
    };
};

const setBookingLimitExceeded = isBookingLimitExceeded => {
    return {
        type: Types.BOOKING_LIMIT_EXCEEDED,
        isBookingLimitExceeded
    };
};

const getBookingCount = (token) => async (dispatch) => {
    try {
        const response = await CheckoutService.getBookingCount(token);
        if (response && response.data) {
            dispatch(getBookingCountSuccess(response.data));
            const {
                count: { BOOKED = 0, EXPIRED = 0, CANCELLED = 0 },
                maxBookingsPerUser,
                maxBookedOrdersPerUser
            } = response.data;
            const isBookingLimitExceeded = (maxBookingsPerUser < (BOOKED + EXPIRED + CANCELLED) || maxBookedOrdersPerUser < BOOKED);
            dispatch(setBookingLimitExceeded(isBookingLimitExceeded));
            return response.data;
        }
        return null;
    } catch (error) {
        return null;
    }
};

const setLoanApplicationStatus = (userDetailsSubmitted, bankingSubmitted) => ({
    type: Types.SET_LOAN_APPLICATION_STATUS,
    userDetailsSubmitted,
    bankingSubmitted
});

const setPreApproved = (preApproved) => ({
    type: Types.SET_PRE_APPROVED,
    preApproved
});

const setZeroDPEligible = (zeroDPEligible) => ({
    type: Types.SET_ZERO_DP_ELIGIBLE,
    zeroDPEligible
});

const AB_TEST_KEYS = {
    DIY_COHORT: "diyCohort",
    DIY_VARIANT: "diyVariant",
    DEFAULT_UPLOAD_BANKING: "defaultUploadBankingABTest",
    BANK_STATEMENT_INSTRUCTIONS: "bankStatementInstructionsABTest",
    TRACK_LOAN: "trackLoanABTest",
    AQB: "aqbABTest",
    PREAPPROVAL_SCREEN_VARIANT: "preApprovalScreenVariant",
    OFFER_ENGINE_VARIANT: "offerEngineABTest",
    PAN_VALIDATION_VARIANT: "panValidationVariant",
    AA_REVAMP_2023_10: "aaThemeRevampVariant"
};

const setABTestFlags = (userCheckData) => ({
    type: Types.SET_AB_TEST_FLAGS,
    showOfferPageFeedbackABTest: userCheckData.showOfferPageFeedback,
    tofBankingAllowedABTest: userCheckData.tofBankingAllowed
});

const setEmploymentType = (employmentType) => ({
    type: Types.SET_EMPLOYMENT_TYPE,
    employmentType
});

const setCFSnackBarStatus = (data) => ({
    type: Types.SET_CF_SNACKBAR_STATUS,
    data
});

const disableFinanceFeedback = () => ({
    type: Types.DISABLE_FINANCE_FEEDBACK
});

const getVariantsFromB2COMSSuccess = (variants) => ({
    type: Types.GET_VARIANTS_FROM_B2C_OMS,
    ...variants
});

const getVariantsFromB2COMS = (token, pincode, clientId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getVariants(token, pincode, clientId).then(response => {
            const variants = response.data.reduce((obj, item) => {
                obj[B2C_OMS_VARIANTS[item.variantName]] = item.variantValue; // map variants from BE to plain object
                return obj;
            }, {});
            dispatch(getVariantsFromB2COMSSuccess(variants));
            resolve(variants);
        }).catch(error => {
            reject(error);
        });
    });
};

const getBuyerAbTestSuccess = (buyerAbTest) => ({
    type: Types.SET_BUYER_AB_TEST,
    buyerAbTest
});

const getBuyerAbTest = ({ pincode, userId, clientId }) => dispatch => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchBuyerAbTest(pincode, userId, clientId).then(response => {
            dispatch(getBuyerAbTestSuccess(response.data.data));
            resolve(response.data.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const updateBuyerAbTestSSRStatus = (buyerABTestSSR) => ({
    type: Types.UPDATE_BUYER_AB_TEST_SSR,
    buyerABTestSSR
});

const getUserConsentData = params => () => {
    return UserService.getUserConsentData(params).then(resp => {
        const { data: { data } } = resp;
        return data;
    }).catch(err => { }); //eslint-disable-line no-unused-vars
};

const submitUserConsentData = params => () => {
    return UserService.submitUserConsentData(params).then(resp => {
        return resp;
    }).catch((err) => { }); //eslint-disable-line no-unused-vars
};

const submitLeadsHandler = params => () => {
    return new Promise((resolve, reject) => {
        CarService.submitLeads(params).then(resp => {
            const { data } = resp;
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
};

const saveUserConsent = (mobile, params) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.saveUserConsent(mobile, params)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
};

const getTotalBooking = (token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.getBookingCount(token)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setPincodeServiceable = (data) => ({
    type: Types.SET_PINCODE_SERVICEABLE,
    data
});

const logoutUser = () => () => {
    return new Promise((resolve, reject) => {
        AuthService.logout().then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

const setCfExperimentVariants = (variants) => ({
    type: Types.SET_CF_EXPERIMENT_VARIANTS,
    ...((variants || []).reduce((obj, item) => {
        obj[AB_TEST_KEYS[item.experiment]] = item.variant;
        return obj;
    }, {}))
});

const setCfConfig = (data) => ({
    type: Types.SET_CF_CONFIG,
    data
});

const setLoanCalculatorConfig = (data) => ({
    type: Types.SET_CALCULATOR_CONFIG,
    data
});

const getCfExperimentVariants = (userId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().getCfExperimentVariants(userId).then(res => {
            const { data } = res.data || {};
            const { variants = [], config = {}, loanCalculatorConfig = {} } = data || {};
            dispatch(setCfExperimentVariants(variants));
            dispatch(setCfConfig(config));
            dispatch(setLoanCalculatorConfig(loanCalculatorConfig));
            resolve(variants);
        }).catch(error => reject(error));
    });
};

const mapClientId = ({ clientId, secureToken }) => () => {
    return new Promise((resolve, reject) => {
        OmsAggregatorService.mapClientId(secureToken, clientId).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

const setBajajOfferStatus = (bajajOfferStatus) => ({
    type: Types.SET_BAJAJ_OFFER_STATUS,
    bajajOfferStatus
});

const saveUserCibilConsent = (payload) => () => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().saveCibilConsent(payload).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

const updateCfSnackBarStatus = (userId, params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ConsumerFinanceService().updateUserDataForSnackBar(userId, params).then((response) => {
            const { data: responseData } = response || {};
            const { data } = responseData || {};
            const { bajajOfferSnackbar } = data || {};
            dispatch(setCFSnackBarStatus(bajajOfferSnackbar));
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setCfUserData = (data) => ({
    type: Types.SET_CF_USER_DATA,
    data
});

const migrateExistingLeadData = (leadData, stableID, gaId) => () => {
    const payload = {
        client_id: stableID || gaId,
        data: leadData
    };
    return new Promise((resolve, reject) => {
        LeadServiceJava.saveLeadFormData(payload).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
};

const setUserCibilStatus = (data) => ({
    type: Types.SET_USER_CIBIL_STATUS,
    data
});

const getUserCibilStatus = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchUserCibilStatus(payload).then((res) => {
            dispatch(setUserCibilStatus(res.data));
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

export {
    setName,
    setProfileName,
    setMobile,
    setProfileMobile,
    setEmail,
    getProfileDetails,
    setCheckAuth,
    setFirstLoad,
    updateLeadType,
    setSocketId,
    setTruecallerPromptCount,
    updateWebviewStatus,
    setGAId,
    setCFSnackBarStatus,
    updateCurrentPageType,
    updateProfile,
    setLastVerifiedMobile,
    checkAuthToken,
    getUserAddresses,
    updateUserAddress,
    addUserAddress,
    getBookingCount,
    setLoanApplicationStatus,
    setPreApproved,
    setZeroDPEligible,
    setABTestFlags,
    setEmploymentType,
    disableFinanceFeedback,
    getVariantsFromB2COMS,
    getBuyerAbTest,
    getUserConsentData,
    submitUserConsentData,
    submitLeadsHandler,
    saveUserConsent,
    updateBuyerAbTestSSRStatus,
    getTotalBooking,
    markUnauthorized,
    setPincodeServiceable,
    logoutUser,
    getCfExperimentVariants,
    mapClientId,
    setBajajOfferStatus,
    saveUserCibilConsent,
    updateCfSnackBarStatus,
    setCfUserData,
    migrateExistingLeadData,
    getUserCibilStatus
};
