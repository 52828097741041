/* eslint-disable max-params */

import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

/* eslint-disable max-statements */
const checkoutApi = (api) => {

    const fetchFinanceDetail = (params) => {
        return api.get(`/finance`, { params });
    };

    const getOrderSummary = (appointmentId, token) => {
        return api.get(`/order/${appointmentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const createOrder = (params, token, source) => {
        return api.post(`/order/checkout/simple/`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    const createOrderWoL = (params, token, source) => {
        return api.post(`/order/checkout/single-step/`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    // eslint-disable-next-line max-params
    const updateFinanceDetail = (payload, orderId, token, source) => {
        return api.put(`/order/${orderId}/finance/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source }) //optional, because same is used in desktop
            },
            version: "v3"
        });
    };

    const updateDeliveryMode = (order, orderId, token) => {
        return api.put(`/order/${orderId}/address`, { ...order }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updateFinancePersonalDetail = (params, orderId, token) => {
        return api.post(`/order/${orderId}/finance/offer`, { ...params }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const getPaymentStatus = (orderId, paymentOrderId, token) => {
        return api.get(`/order/${orderId}/paymentOrderId/${paymentOrderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updatePaymentOrderAndGetOrderIdWithPaymentId = (orderId, token) => {
        return api.put(`/order/${orderId}/payment`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const updateOrderBooking = (orderId, token) => {
        return api.put(`/order/${orderId}/book`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const cancelOrder = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/cancel`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getPaymentSummary = (token, orderId) => {
        return api.get(`/order/post-booking/${orderId}/payment-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getNeftInfo = (token, orderId) => {
        return api.get(`/order/post-booking/${orderId}/get-neft-info`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const initPostBookingPayment = (token, orderId, amount) => {
        return api.get(`/order/post-booking/${orderId}/init-payment?paymentAmount=${amount}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getPostBookingPaymentStatus = (token, orderId, paymentId) => {
        return api.get(`/order/post-booking/${orderId}/payment-status/${paymentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getPaymentHistory = (token, orderId) => {
        return api.get(`/order/post-booking/${orderId}/transaction/log`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const returnCar = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/return`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const raiseRepairRequest = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/issue`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getMyBookings = (token, params) => {
        return api.get(`/order/user/app`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                mediaSource: "HELLO_AR"
            },
            params
        });
    };

    const getBookingSummary = (token, orderId) => {
        return api.get(`/order/${orderId}/summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getBookingSummaryRevamp = (token, orderId) => {
        return api.get(`/order/${orderId}/post-delivery-summary`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getCancellationReasons = (orderId, token) => {
        return api.get(`/order/${orderId}/cancel`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getPerfiosUrl = (orderId, params, token) => {
        return api.get(`/order/${orderId}/bank-statement/perfios`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const getUserLatestOrder = (token) => {
        return api.get(`/order/user/latest`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const getDeliverySlots = (orderId, token, params) => {
        return api.get(`/order/${orderId}/delivery-slots`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const sendRating = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/rating`, { ...payload }, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getAllTickets = (token) => {
        return api.get(`/ticket`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getDeeplinkingPerfiosUrl = (params, token, source) => {
        return api.get(`/order/bank-statement/perfios/deeplink`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                SOURCE: source
            },
            params
        });
    };

    const getAllFaqs = (token) => {
        return api.get(`/zendesk/help-center/faqs`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getUserAddresses = (token) => {
        return api.get(`/user-details/address`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const raiseTicket = (token, payload) => {
        return api.post(`/zendesk/tickets`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
                // "Content-Type": "multipart/form-data"
            }
        });
    };
    const applyCouponCode = (orderId, token, couponCode) => {
        return api.put(`/order/${orderId}/coupon/${couponCode}/apply`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
                // "Content-Type": "multipart/form-data"
            }
        });
    };

    const updateUserAddress = (token, id, payload) => {
        return api.put(`/user-details/address/${id}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getAllArticles = (token, sectionId) => {
        return api.get(`/zendesk/help-center/faqs/${sectionId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const addUserAddress = (token, payload) => {
        return api.post(`/user-details/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const verifyPayment = (bookingId, orderId, token) => {
        return api.get(
            `/order/${bookingId}/payment/${orderId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "car"
                }
            }
        );
    };

    const cfStatusVerify = ({ appointmentId, listingPrice, secureToken }) => {
        return api.get(
            `/user-details/cfstatus?appointmentId=${appointmentId}&vehiclePrice=${listingPrice}`,
            {
                headers: {
                    Authorization: `Bearer ${secureToken}`,
                    X_COUNTRY: "IN",
                    X_VEHICLE_TYPE: "car",
                    SOURCE: "mSite"
                }
            }
        );
    };

    // const { data: paymentStatus } = await

    const fetchReturnSlots = (orderId, token, params) => {
        return api.get(`/order/${orderId}/return/slots`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const removeCouponCode = (orderId, token, couponCode) => {
        return api.put(`/order/${orderId}/coupon/${couponCode}/cancel`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const fetchJuspayPayload = (token, oriderId, payload) => {
        return api.post(`/order/${oriderId}/juspay/payment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getInspectionStates = (orderId, token) => {
        return api.get(`/order/${orderId}/inspection`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getInspectionMaster = (orderId, token) => {
        return api.get(`/order/${orderId}/inspection-master-data`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const validateInspectionCode = (orderId, token, code) => {
        return api.post(`/order/${orderId}/validate-inspection`, code, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const inspectionResult = (orderId, token, data) => {
        return api.put(`/order/${orderId}/inspection`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const uploadDefects = (orderId, token, data) => {
        return api.post(`/order/${orderId}/inspection-image`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getInspectionResult = (orderId, token) => {
        return api.get(`/order/${orderId}/inspection-result`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };
    const getServiceablePincode = (token, orderId, params) => {
        return api.get(`/order/${orderId}/postdelivery/repair/slots`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            },
            params,
            version: "v3"
        });
    };
    // eslint-disable-next-line max-params
    const sendRepairInfo = (token, orderId, data, source) => {
        return api.post(`/order/${orderId}/postdelivery/repair`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                "content-type": "multipart/form-data;",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    const applyInspectionOffer = (orderId, token, data) => {
        return api.post(`/order/${orderId}/inspection-result/apply`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const isEligibleForInspection = (orderId, token, params) => {
        return api.get(`/order/${orderId}/inspection-enabled`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    // eslint-disable-next-line max-params
    const submitRepairReceipt = (token, orderId, repairId, data) => {
        return api.put(`/order/${orderId}/postdelivery/repair/${repairId}/self/receipt`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getCaVirtualNumber = (orderId, token) => {
        return api.get(`/order/ca-virtual-number/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    // eslint-disable-next-line max-params
    const getPaymentLinkInitiated = (token, orderId, params) => {
        return api.get(`/order/${orderId}/razorPay/payout/link`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            },
            params
        });
    };

    const getClaimStatus = (token, orderId, params) => {
        return api.get(`order/${orderId}/razorpay/payout/status`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR"
            },
            params
        });
    };

    const getBookingCount = (token) => {
        return api.get(`order/status-count`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getVariants = (token, pincode, clientId) => {
        return api.get(`/user-details/variant`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(clientId && {clientId})
            },
            params: {
                ...(pincode && { pincode })
            }
        });
    };

    const submitRcAddress = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/courier/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getDeliveryScheduleSlots = (orderId, token, params) => {
        return api.get(`/order/${orderId}/delivery-schedule-slots`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const getDeliverySlotsV2 = (orderId, token, params) => {
        return api.get(`/order/${orderId ? `${orderId}/` : ""}delivery-slots`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v3"
        });
    };

    const getBookingAmount = (orderId, token, params) => {
        return api.get(`/order/${orderId ? `${orderId}/` : ""}booking-amount`, {
            headers: {
                ...(token && { Authorization: `Bearer ${token}` }),
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params,
            version: "v3"
        });
    };

    const updateTestDriveDetail = (payload, orderId, token, source) => {
        return api.put(`/order/${orderId}/test-drive/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    const confirmFreeBooking = (payload, orderId, token, source, bi2bcExperiment) => {
        return api.put(`/order/${orderId}/confirm-booking`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car",
                ...(source && { SOURCE: source })
            },
            version: bi2bcExperiment ? "v4" : "v3"
        });
    };

    const initiateOrderPayment = ({ payload, paymentMethod, orderId, token, source, bi2bcExperiment }) => {
        return api.put(`/order/${orderId}/${paymentMethod}/init-payment`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                ...(source && { SOURCE: source })
            },
            version: bi2bcExperiment ? "v4" : "v3"
        });
    };

    const submitRcPendingDoc = (orderId, token, payload) => {
        return api.post(`/order/${orderId}/upload/document`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const cancelReturnRequest = (orderId, token) => {
        return api.put(`/order/${orderId}/return/cancel`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const saveDeliveryScheduleSlots = (orderId, token, payload) => {
        return api.put(`/order/${orderId}/postbooking/delivery-schedule`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updatePaymentOption = (payload, orderId, token, source) => {
        return api.put(`/order/${orderId}/payment-option/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "CAR",
                ...(source && { SOURCE: source })
            },
            version: "v3"
        });
    };

    const cancelRepairRequest = (orderId, token, params) => {
        return api.delete(`/order/${orderId}/postdelivery/repair`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const repairReschedule = (orderId, token, params, payload) => {
        return api.put(`order/${orderId}/postdelivery/repair/logistic/reschedule`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            params
        });
    };

    const applyCoupon = (token, orderId, couponCode, payload) => {
        return api.put(`order/${orderId}/coupon/${couponCode}/apply`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const removeCoupon = (token, orderId, couponCode) => {
        return api.put(`order/${orderId}/coupon/${couponCode}/cancel`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const updatePaymentMode = (token, orderId, financeMode) => {
        return api.put(`order/${orderId}/finance/${financeMode}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const raiseCancelRequest = (token, orderId) => {
        return api.put(`order/${orderId}/cancel-request`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const stopCancellation = (token, orderId) => {
        return api.delete(`order/${orderId}/cancel-request`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const addMoreCarSubmit = (token, payload) => {
        return api.post(`add-more-cars`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getAddMoreCarData = (token, orderId) => {
        return api.get(`add-more-cars/link/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getCarStatus = (token, orderId, appId) => {
        return api.get(`add-more-cars/${orderId}/same-slot/${appId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const rescheduleAddMoreCar = (token, payload) => {
        return api.put(`add-more-cars/reschedule`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const bulkCancellation = (token, payload) => {
        return api.put(`/add-more-cars/cancel`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getTestDrivePaymentStatus = ({orderId, token}) => {
        return api.post(`/order/${orderId}/payment/status`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            },
            version: "v3"
        });
    };

    const fetchUserCibilStatus = ({token}) => {
        return api.get(`/user-details/cibil-status`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    return {
        fetchFinanceDetail,
        updateFinancePersonalDetail,
        getOrderSummary,
        createOrder,
        updateFinanceDetail,
        updateDeliveryMode,
        getPaymentStatus,
        updatePaymentOrderAndGetOrderIdWithPaymentId,
        updateOrderBooking,
        cancelOrder,
        getPaymentSummary,
        getNeftInfo,
        initPostBookingPayment,
        getPostBookingPaymentStatus,
        getPaymentHistory,
        returnCar,
        raiseRepairRequest,
        getMyBookings,
        getBookingSummary,
        getBookingSummaryRevamp,
        getCancellationReasons,
        getUserLatestOrder,
        getPerfiosUrl,
        getDeliverySlots,
        sendRating,
        getAllTickets,
        getDeeplinkingPerfiosUrl,
        getAllFaqs,
        raiseTicket,
        getAllArticles,
        getUserAddresses,
        updateUserAddress,
        addUserAddress,
        verifyPayment,
        cfStatusVerify,
        fetchReturnSlots,
        applyCouponCode,
        removeCouponCode,
        fetchJuspayPayload,
        getInspectionStates,
        getInspectionMaster,
        validateInspectionCode,
        inspectionResult,
        uploadDefects,
        getInspectionResult,
        applyInspectionOffer,
        isEligibleForInspection,
        getServiceablePincode,
        sendRepairInfo,
        submitRepairReceipt,
        getCaVirtualNumber,
        getPaymentLinkInitiated,
        getClaimStatus,
        getBookingCount,
        submitRcAddress,
        submitRcPendingDoc,
        cancelReturnRequest,
        getVariants,
        getDeliveryScheduleSlots,
        saveDeliveryScheduleSlots,
        getDeliverySlotsV2,
        getBookingAmount,
        updateTestDriveDetail,
        confirmFreeBooking,
        initiateOrderPayment,
        updatePaymentOption,
        cancelRepairRequest,
        repairReschedule,
        applyCoupon,
        removeCoupon,
        createOrderWoL,
        updatePaymentMode,
        raiseCancelRequest,
        stopCancellation,
        addMoreCarSubmit,
        getAddMoreCarData,
        getCarStatus,
        rescheduleAddMoreCar,
        bulkCancellation,
        getTestDrivePaymentStatus,
        fetchUserCibilStatus
    };
};

export const CheckoutService = checkoutApi(middlewareConfig(MIDDLEWARE_ENUMS.CHECKOUT_CONST));
