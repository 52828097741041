
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import Types from "./types";

const updateSSRAppliedFilters = (data) => ({
    type: Types.UPDATE_SSR_APPLIED_FILTER,
    data
});

const updateSelectedOnScreenFilter = (selectedOnScreenFilter) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter
});

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    ...data
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = ({data, clientId}) => (dispatch) => {
    dispatch(fetchFiltersInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.searchFilter({data, clientId})
            .then((response) => {
                dispatch(fetchFilterSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchFiltersFailure());
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateFilterOptionData = (data) => ({
    type: Types.UPDATE_FILTER_OPTION_DATA,
    data
});

const updateAppliedFilters = (data) => ({
    type: Types.UPDATE_APPLIED_FILTERS,
    data
});

const copyAppliedToSelectedFilters = () => ({
    type: Types.COPY_APPLIED_TO_SELECTED_FILTER
});

const clearSelectedFilterCategory = (data) => ({
    type: Types.CLEAR_SELECTED_FILTER_CATEGORY,
    data
});

const clearBulkSelectedFilterCategory = (data) => ({
    type: Types.CLEAR_BULK_SELECTED_FILTER_CATEGORY,
    data
});

const clearAppliedFilterCategory = (data) => ({
    type: Types.CLEAR_APPLIED_FILTER_CATEGORY,
    data
});

const copySelectedToLastAppliedFilters = () => ({
    type: Types.COPY_SELECTED_TO_LAST_APPLIED_FILTERS
});

const setLastAppliedFilter = (data) => ({
    type: Types.SET_LAST_APPLIED_FILTER,
    data
});

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const updateRecentSearches = (data) => ({
    type: Types.UPDATE_RECENT_SEARCHES,
    data
});

const setIsIndiaPage = (isIndiaPage = true) => ({
    type: Types.SET_INDIA_PAGE,
    isIndiaPage
});

const setIndiaPageUrl = (indiaPageUrl = "") => ({
    type: Types.SET_INDIA_PAGE_URL,
    indiaPageUrl
});

const setFilterSearchQuery = (filterSearchQuery) => ({
    type: Types.SET_FILTER_SEARCH_QUERY,
    filterSearchQuery
});

const setFetchedFilterCityId = (fetchedFilterCityId) => ({
    type: Types.SET_FETCHED_FILTER_CITY_ID,
    fetchedFilterCityId
});

const resetSelectedFilter = () => ({
    type: Types.SET_RESET_SELECTED_FILTERS
});

const getSearchSuggestions = (text = "") => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getSearchSuggestions(text)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const resetSelectedFilterCheckout = () => ({
    type: Types.SET_RESET_SELECTED_FILTERS_CHECKOUT
});

const resetAppliedFilters = () => ({
    type: Types.RESET_APPLIED_FILTERS
});

const getGuideDataSuccess = (guideData) => ({
    type: Types.GET_FILTER_GUIDE,
    guideData
});

const getGuideData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getFilterGuide()
            .then((response) => {
                dispatch(getGuideDataSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const toggleLoader = () => ({
    type: Types.TOGGLE_LOADER
});

export {
    updateSSRAppliedFilters,
    updateFilterOptionData,
    updateSSRStatus,
    updateSelectedOnScreenFilter,
    clearSelectedFilterCategory,
    clearAppliedFilterCategory,
    copyAppliedToSelectedFilters,
    updateAppliedFilters,
    fetchFilters,
    copySelectedToLastAppliedFilters,
    setLastAppliedFilter,
    updateFilterTabType,
    updateRecentSearches,
    setIsIndiaPage,
    setIndiaPageUrl,
    setFilterSearchQuery,
    resetSelectedFilter,
    getSearchSuggestions,
    setFetchedFilterCityId,
    resetSelectedFilterCheckout,
    resetAppliedFilters,
    fetchFilterSuccess,
    clearBulkSelectedFilterCategory,
    getGuideData,
    toggleLoader
};
