const currentEnv = process.env.HOST_ENV;

const appUrl = () => {
    const urls = {
        LOCAL: "http://localhost:3000",
        QA: "https://c2b-website.qac24svc.dev",
        STAGING: "https://stage-c2b-website.qac24svc.dev",
        LNOD: "https://stage-c2b-website.qac24svc.dev",
        PRODUCTION: "https://www.cars24.com"
    };
    return urls[currentEnv];
};

const baseApiUrl = () => {
    const urls = {
        LOCAL: "https://sell24-engine.qac24svc.dev",
        QA: "https://sell24-engine.qac24svc.dev",
        STAGING: "https://stage-sell24-engine.qac24svc.dev",
        LNOD: "https://stage-sell24-engine.qac24svc.dev",
        PRODUCTION: "https://api-sell24.cars24.team"
    };
    return urls[currentEnv];
};

const apiUrl = () => {
    const urls = {
        LOCAL: "https://c2c-web.qac24svc.dev",
        STAGING: "https://api-staging.qac24svc.dev",
        QA: "https://api-se-qa1.qac24svc.dev",
        LNOD: "https://sellerengine-qa8.ninja24.in",
        PRODUCTION: "https://api.cars24.com"
    };
    return urls[currentEnv];
};

const ssrApiUrl = () => {
    const urls = {
        LOCAL: "http://c2c-web.qac24svc.dev",
        STAGING: "http://api-staging.qac24svc.dev",
        QA: "http://api-se-qa1.qac24svc.dev",
        LNOD: "http://sellerengine-qa8.ninja24.in",
        PRODUCTION: "http://api.cars24.com"
    };
    return urls[currentEnv];
};

const vehicleApiUrl = () => {
    const urls = {
        LOCAL: "https://vehicle-service.qac24svc.dev",
        STAGING: "https://stage-vehicle-service.qac24svc.dev",
        QA: "https://vehicle-service.qac24svc.dev",
        LNOD: "https://vehicle-service.qac24svc.dev",
        PRODUCTION: "https://vehicle.cars24.team"
    };
    return urls[currentEnv];
};

const vehicleSSRApiUrl = () => {
    const urls = {
        LOCAL: "http://vehicle-service.qac24svc.dev",
        STAGING: "http://stage-vehicle-service.qac24svc.dev",
        QA: "http://vehicle-service.qac24svc.dev",
        LNOD: "http://vehicle-service.qac24svc.dev",
        // PRODUCTION: "https://vehicle.cars24.team"
        PRODUCTION: "http://vehicle-service-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const staticFastlyAppUrl = () => {
    const urls = {
        LOCAL: "https://fastly-production.24c.in",
        QA: "https://fastly-production.24c.in",
        STAGING: "https://fastly-production.24c.in",
        LNOD: "https://fastly-production.24c.in",
        PRODUCTION: "https://fastly-production.24c.in"
    };
    return urls[currentEnv];
};

const getPrivateProductApiKey = () => {
    const urls = {
        QA: "c2c-s4583HJKH58",
        STAGING: "pp-ui-s32578KJDHS",
        PRODUCTION: "qGuMZcWGxZpgd8uSH4rgkal4v1evAlCd"
    };
    return urls[currentEnv];
};

const getC2bProductApiKey = () => {
    const urls = {
        QA: "seller_engine_mweb_12985",
        STAGING: "seller_engine_mweb_12985",
        PRODUCTION: "K3USo6xQXQ&bn!Eu@x&LVWjy74eoGaVq"
    };
    return urls[currentEnv];
};

const ppApiUrl = () => {
    const urls = {
        LOCAL: "https://pvt-product.qac24svc.dev",
        QA: "https://pvt-product.qac24svc.dev",
        STAGING: "https://stage-pvt-product.qac24svc.dev",
        LNOD: "https://stage-pvt-product.qac24svc.dev",
        PRODUCTION: "https://pvt-product.cars24.com"
    };
    return urls[currentEnv];
};

const c2bKeys = {
    LOCAL: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    QA: "YzJiX2Zyb250ZW5kOkszcHJyT0cwNm1uZE41V0FMS0NTQUxuQVd3eHBXQ0hJ",
    STAGING: "YzJiX2Zyb250ZW5kOmJBVllHRVlzODZIWDVDd01jcmo5M01DQklvOEs2b2xo",
    PRODUCTION: "YzJiX2Zyb250ZW5kOko1SXRmQTk2bTJfY3lRVk00dEtOSnBYaFJ0c0NtY1h1"
};

const c2bAuthKey = () => {
    return c2bKeys[currentEnv];
};

const lmsBaseApiUrl = () => {
    const urls = {
        LOCAL: "https://c2c-lms.qac24svc.dev",
        STAGING: "https://c2c-lms-stage.cars24.team",
        QA: "https://c2c-lms.qac24svc.dev",
        LNOD: "https://c2c-lms.qac24svc.dev",
        PRODUCTION: "https://c2c-lms.cars24.team"
    };
    return urls[currentEnv];
};

const lmsAuthKey = () => {
    const keys = {
        LOCAL: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        QA: "8a223df3-9fbd-426e-aa2f-e79bdcf60f61",
        STAGING: "01e72f78-24f1-434a-ab6b-3342d75c352e",
        PRODUCTION: "b78f06db-d0d7-4c60-b1a6-fc153d2527be"
    };
    return keys[currentEnv];
};

const lmsLeadAuthKey = () => {
    const keys = {
        LOCAL: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        QA: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        STAGING: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        PRODUCTION: "6f355999-4af7-4c3a-8816-a16aaf2d8994"
    };
    return keys[currentEnv];
};

const getLogoURL = () => {
    return `${staticFastlyAppUrl()}/cars24/seo/static/1_20230830_1693395013.png`;
};

const getDiyOgImageUrl = () => {
    return `${staticFastlyAppUrl()}/cars24/seo/static/53_20230201_1675251512.jpg`;
};

const exchangeApiUrl = () => {
    const urls = {
        LOCAL: "https://c2b-website.qac24svc.dev",
        QA: "https://exchange-service-qa.ninja24.in",
        STAGING: "https://exchange-service-stage.cars24.team",
        LNOD: "https://exchange-service-qa.ninja24.in",
        PRODUCTION: "https://exchange-service.cars24.team"
    };
    return urls[currentEnv];
};

const checkoutApiSSRUrl = () => {
    const urls = {
        LOCAL: "https://b2c-oms.qac24svc.dev",
        QA: "https://b2c-oms.qac24svc.dev",
        STAGING: "http://stage-b2c-oms.qac24svc.dev",
        LNOD: "https://b2c-oms.qac24svc.dev",
        PRODUCTION: "https://b2c-oms.c24.tech"
    };
    return urls[currentEnv];
};

const checkoutApiUrl = () => {
    const urls = {
        LOCAL: "https://b2c-oms.qac24svc.dev",
        QA: "https://b2c-oms.qac24svc.dev",
        STAGING: "https://stage-b2c-oms.qac24svc.dev",
        LNOD: "https://b2c-oms.qac24svc.dev",
        PRODUCTION: "https://b2c-oms.c24.tech"
    };
    return urls[currentEnv];
};

const omsAggregatorApiSSRUrl = () => {
    const urls = {
        LOCAL: "http://oms-aggregator-service.qac24svc.dev",
        QA: "http://oms-aggregator-service.qac24svc.dev",
        STAGING: "http://stage-oms-aggregator-service.qac24svc.dev",
        LNOD: "http://oms-aggregator-service.qac24svc.dev",
        PRODUCTION: "http://oms-aggregator-service.c24.tech"
    };
    return urls[currentEnv];
};

const omsAggregatorApi = () => {
    const urls = {
        LOCAL: "https://oms-aggregator-service.qac24svc.dev",
        QA: "https://oms-aggregator-service.qac24svc.dev",
        STAGING: "https://stage-oms-aggregator-service.qac24svc.dev",
        LNOD: "https://oms-aggregator-service.qac24svc.dev",
        PRODUCTION: "https://oms-aggregator-service.c24.tech"
    };
    return urls[currentEnv];
};

const consumerFinanceApiUrl = () => {
    const urls = {
        LOCAL: "https://stage-consumer-finance-engine.qac24svc.dev/",
        QA: "https://stage-consumer-finance-engine.qac24svc.dev/",
        STAGING: "https://stage-consumer-finance-engine.qac24svc.dev/",
        LNOD: "https://consumer-finance-stage.cars24.team/",
        PRODUCTION: "https://simpler-engine.cars24.team/"
    };
    return urls[currentEnv];
};

const consumerFinanceSSRApiUrl = () => {
    const urls = {
        LOCAL: "https://stage-consumer-finance-engine.qac24svc.dev/",
        QA: "https://stage-consumer-finance-engine.qac24svc.dev/",
        STAGING: "https://stage-consumer-finance-engine.qac24svc.dev/",
        LNOD: "https://consumer-finance-stage.cars24.team/",
        PRODUCTION: "http://simpler-engine.cars24-service:8080/"
    };
    return urls[currentEnv];
};

const getConsumerFinanceApiKey = () => {
    const keys = {
        QA: "17d12855-df5d-4971-b4eb-dc3e3cd283aa",
        STAGING: "17d12855-df5d-4971-b4eb-dc3e3cd283aa",
        PRODUCTION: "2b1dd9f0-0ede-47e6-be88-4e99c0eb7968"
    };
    return keys[currentEnv];
};

const listingApiUrl = () => {
    const urls = {
        LOCAL: "https://listing-service.qac24svc.dev",
        QA: "https://listing-service.qac24svc.dev",
        STAGING: "https://stage-listing-service.qac24svc.dev",
        LNOD: "https://listing-service.qac24svc.dev",
        PRODUCTION: "https://listing-service.c24.tech"
    };
    return urls[currentEnv];
};

const leadApiUrlKey = () => {
    const keys = {
        LOCAL: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        QA: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        STAGING: "6f355999-4af7-4c3a-8816-a16aaf2d8994",
        PRODUCTION: "6f355999-4af7-4c3a-8816-a16aaf2d8994"
    };
    return keys[currentEnv];
};

const leadServiceJavaApi = () => {
    const urls = {
        LOCAL: "https://lead-service-java.qac24svc.dev",
        STAGING: "https://stage-lead-service-java.qac24svc.dev",
        QA: "https://lead-service-java.qac24svc.dev",
        LNOD: "https://lead-service-java.qac24svc.dev",
        PRODUCTION: "https://seller-lead.cars24.team"
    };
    return urls[currentEnv];
};

const leadServiceJavaSSRApi = () => {
    const urls = {
        LOCAL: "http://lead-service-java.qac24svc.dev",
        // STAGING: "http://lead-service-java.stage",
        STAGING: "https://stage-lead-service-java.qac24svc.dev",
        QA: "https://lead-service-java.qac24svc.dev",
        // QA: "http://lead-service-java.qa",
        LNOD: "http://lead-service-java.qac24svc.dev",
        // PRODUCTION: "https://seller-lead.cars24.team",
        PRODUCTION: "http://seller-lead-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const b2cUserServiceUrl = () => {
    const urls = {
        LOCAL: "https://b2c-user-service.qac24svc.dev",
        STAGING: "https://stage-b2c-user-service.qac24svc.dev",
        QA: "https://b2c-user-service.qac24svc.dev",
        LNOD: "https://b2c-user-service.qac24svc.dev",
        PRODUCTION: "https://b2c-user-service.c24.tech"
    };
    return urls[currentEnv];
};

const carConsumerGatewayApi = () => {
    const urls = {
        LOCAL: "https://cars-consumer-gateway.qac24svc.dev",
        STAGING: "https://stage-cars-consumer-gateway.qac24svc.dev",
        QA: "https://cars-consumer-gateway.qac24svc.dev",
        LNOD: "https://cars-consumer-gateway.qac24svc.dev",
        PRODUCTION: "https://cars-consumer.cars24.team"
    };
    return urls[currentEnv];
};

const carConsumerGatewaySSRApi = () => {
    const urls = {
        LOCAL: "https://cars-consumer-gateway.qac24svc.dev",
        STAGING: "https://stage-cars-consumer-gateway.qac24svc.dev",
        QA: "https://cars-consumer-gateway.qac24svc.dev",
        LNOD: "https://cars-consumer-gateway.qac24svc.dev",
        // PRODUCTION: "http://cars-consumer.cars24.team",
        PRODUCTION: "http://cars-consumer-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const incidentManagement = () => {
    const urls = {
        LOCAL: "https://incident-management-system.qac24svc.dev",
        STAGING: "https://stage-incident-management-system.qac24svc.dev",
        QA: "https://incident-management-system.qac24svc.dev",
        LNOD: "https://incident-management-system.qac24svc.dev",
        PRODUCTION: "https://incident-management-system.c24.tech"
    };
    return urls[currentEnv];
};

const b2cCrmServiceUrl = () => {
    const urls = {
        LOCAL: "https://b2c-crm.qac24svc.dev",
        STAGING: "https://stage-b2c-crm.qac24svc.dev",
        QA: "https://b2c-crm.qac24svc.dev",
        LNOD: "https://b2c-crm.qac24svc.dev",
        PRODUCTION: "https://b2c-crm.c24.tech"
    };
    return urls[currentEnv];
};

const couponServiceUrl = () => {
    const urls = {
        LOCAL: "https://coupon-service.qac24svc.dev",
        STAGING: "https://stage-coupon-service.qac24svc.dev",
        QA: "https://coupon-service.qac24svc.dev",
        LNOD: "https://coupon-service.qac24svc.dev",
        PRODUCTION: "https://coupon-service.c24.tech"
    };
    return urls[currentEnv];
};

const catalogServiceUrl = () => {
    const urls = {
        LOCAL: "https://b2c-catalog-service.qac24svc.dev",
        STAGING: "https://stage-b2c-catalog-service.qac24svc.dev",
        QA: "https://b2c-catalog-service.qac24svc.dev",
        LNOD: "https://b2c-catalog-gateway.qac24svc.dev",
        PRODUCTION: "https://b2c-catalog-service.c24.tech"
    };
    return urls[currentEnv];
};

const catalogGatewayUrl = () => {
    const urls = {
        LOCAL: "https://b2c-catalog-gateway.qac24svc.dev",
        STAGING: "https://stage-b2c-catalog-gateway.qac24svc.dev",
        QA: "https://b2c-catalog-gateway.qac24svc.dev",
        LNOD: "https://b2c-catalog-gateway.qac24svc.dev",
        PRODUCTION: "https://b2c-catalog-gateway.c24.tech"
    };
    return urls[currentEnv];
};

const catalogGatewaySSRUrl = () => {
    const urls = {
        LOCAL: "https://b2c-catalog-gateway.qac24svc.dev",
        STAGING: "https://stage-b2c-catalog-gateway.qac24svc.dev",
        QA: "https://b2c-catalog-gateway.qac24svc.dev",
        LNOD: "https://b2c-catalog-gateway.qac24svc.dev",
        PRODUCTION: "https://b2c-catalog-gateway.c24.tech"
    };
    return urls[currentEnv];
};

const c2cBaseUrl = () => {
    const urls = {
        LOCAL: "https://sell24-engine.qac24svc.dev",
        STAGING: "https://stage-sell24-engine.qac24svc.dev",
        QA: "https://sell24-engine.qac24svc.dev",
        LNOD: "https://sell24engine-lnod.ninja24.in",
        PRODUCTION: "https://api-sell24.cars24.team"
    };
    return urls[currentEnv];
};

const c2cBaseSSRUrl = () => {
    const urls = {
        LOCAL: "https://sell24-engine.qac24svc.dev",
        STAGING: "https://stage-sell24-engine.qac24svc.dev",
        QA: "https://sell24-engine.qac24svc.dev",
        LNOD: "https://sell24engine-lnod.ninja24.in",
        // PRODUCTION: "https://api-sell24.cars24.team"
        PRODUCTION: "http://sell24-engine-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const cmsSSRUrl = () => {
    const urls = {
        LOCAL: "https://new-car-api.qac24svc.dev",
        STAGING: "https://new-car-api.qac24svc.dev",
        QA: "https://new-car-api.qac24svc.dev",
        LNOD: "https://new-car-api.qac24svc.dev",
        PRODUCTION: "http://new-car-api-prod.cars24-service:8080"
        // PRODUCTION: "https://new-car-api-prod.cars24.team"
    };
    return urls[currentEnv];
};

const cmsUrl = () => {
    const urls = {
        LOCAL: "https://new-car-api.qac24svc.dev",
        STAGING: "https://new-car-api.qac24svc.dev",
        QA: "https://new-car-api.qac24svc.dev",
        LNOD: "https://new-car-api.qac24svc.dev",
        PRODUCTION: "https://new-car-api-prod.cars24.team"
    };
    return urls[currentEnv];
};

const cmsApiKeys = () => {
    const urls = {
        LOCAL: "13yVBahLnU5j/K?YmOnmmG5?Lt3LN9c1tZ?DiItAtVW2KXOBvgFevwL19-=i2lJZEy7E0cDTcC6zno0jbv8T1wYdredQTw!KGUH0e60CmPMx/exMCBdGDG9EXeXbcJp8DJkShid3qNws59o5g8s=uvnKxC3RndXU64B=1xe75ITpK11Nt4?2HTFxo-c/rqo-U8MP4PPFHdQJ00kwT=24hqm9Yt12KpDZZK9IClwW6u-Xgs/W?E4wNgAQKSze2=5m",
        STAGING: "13yVBahLnU5j/K?YmOnmmG5?Lt3LN9c1tZ?DiItAtVW2KXOBvgFevwL19-=i2lJZEy7E0cDTcC6zno0jbv8T1wYdredQTw!KGUH0e60CmPMx/exMCBdGDG9EXeXbcJp8DJkShid3qNws59o5g8s=uvnKxC3RndXU64B=1xe75ITpK11Nt4?2HTFxo-c/rqo-U8MP4PPFHdQJ00kwT=24hqm9Yt12KpDZZK9IClwW6u-Xgs/W?E4wNgAQKSze2=5m",
        QA: "13yVBahLnU5j/K?YmOnmmG5?Lt3LN9c1tZ?DiItAtVW2KXOBvgFevwL19-=i2lJZEy7E0cDTcC6zno0jbv8T1wYdredQTw!KGUH0e60CmPMx/exMCBdGDG9EXeXbcJp8DJkShid3qNws59o5g8s=uvnKxC3RndXU64B=1xe75ITpK11Nt4?2HTFxo-c/rqo-U8MP4PPFHdQJ00kwT=24hqm9Yt12KpDZZK9IClwW6u-Xgs/W?E4wNgAQKSze2=5m",
        LNOD: "13yVBahLnU5j/K?YmOnmmG5?Lt3LN9c1tZ?DiItAtVW2KXOBvgFevwL19-=i2lJZEy7E0cDTcC6zno0jbv8T1wYdredQTw!KGUH0e60CmPMx/exMCBdGDG9EXeXbcJp8DJkShid3qNws59o5g8s=uvnKxC3RndXU64B=1xe75ITpK11Nt4?2HTFxo-c/rqo-U8MP4PPFHdQJ00kwT=24hqm9Yt12KpDZZK9IClwW6u-Xgs/W?E4wNgAQKSze2=5m",
        PRODUCTION: "13yVBahLnU5j/K?YmOnmmG5?Lt3LN9c1tZ?DiItAtVW2KXOBvgFevwL19-=i2lJZEy7E0cDTcC6zno0jbv8T1wYdredQTw!KGUH0e60CmPMx/exMCBdGDG9EXeXbcJp8DJkShid3qNws59o5g8s=uvnKxC3RndXU64B=1xe75ITpK11Nt4?2HTFxo-c/rqo-U8MP4PPFHdQJ00kwT=24hqm9Yt12KpDZZK9IClwW6u-Xgs/W?E4wNgAQKSze2=5m"
    };
    return urls[currentEnv];
};

const vasCmsApiKeys = () => {
    const urls = {
        LOCAL: "f0f479694b1a4e12b9f1a7664c?!ae7735/516937e7e8e74?!920ba1b?!1ebb0f2f70eb",
        STAGING: "f0f479694b1a4e12b9f1a7664c?!ae7735/516937e7e8e74?!920ba1b?!1ebb0f2f70eb",
        QA: "f0f479694b1a4e12b9f1a7664c?!ae7735/516937e7e8e74?!920ba1b?!1ebb0f2f70eb",
        LNOD: "f0f479694b1a4e12b9f1a7664c?!ae7735/516937e7e8e74?!920ba1b?!1ebb0f2f70eb",
        PRODUCTION: "f0f479694b1a4e12b9f1a7664c?!ae7735/516937e7e8e74?!920ba1b?!1ebb0f2f70eb"
    };
    return urls[currentEnv];
};

const newCarsCmsApiKeys = () => {
    const urls = {
        LOCAL: "f7zNw-4E!K2RMM==JPp3bjMBPn8W?Ta99?g75vC-tJs/VULP3FMy/e24vp6Q?ikz348nGH99jUF77eN2AVcZdaDcZPrHG44aUs!d27R7T!FA8lk=Qi65/98RE=4qzpsQ?Ml/RvyJrpDHJn-AtKbxj3vYVt6FII?g41ewwV?ItqP?9k0djiKJk5K?H8.j",
        STAGING: "f7zNw-4E!K2RMM==JPp3bjMBPn8W?Ta99?g75vC-tJs/VULP3FMy/e24vp6Q?ikz348nGH99jUF77eN2AVcZdaDcZPrHG44aUs!d27R7T!FA8lk=Qi65/98RE=4qzpsQ?Ml/RvyJrpDHJn-AtKbxj3vYVt6FII?g41ewwV?ItqP?9k0djiKJk5K?H8.j",
        QA: "f7zNw-4E!K2RMM==JPp3bjMBPn8W?Ta99?g75vC-tJs/VULP3FMy/e24vp6Q?ikz348nGH99jUF77eN2AVcZdaDcZPrHG44aUs!d27R7T!FA8lk=Qi65/98RE=4qzpsQ?Ml/RvyJrpDHJn-AtKbxj3vYVt6FII?g41ewwV?ItqP?9k0djiKJk5K?H8.j",
        LNOD: "f7zNw-4E!K2RMM==JPp3bjMBPn8W?Ta99?g75vC-tJs/VULP3FMy/e24vp6Q?ikz348nGH99jUF77eN2AVcZdaDcZPrHG44aUs!d27R7T!FA8lk=Qi65/98RE=4qzpsQ?Ml/RvyJrpDHJn-AtKbxj3vYVt6FII?g41ewwV?ItqP?9k0djiKJk5K?H8.j",
        PRODUCTION: "f7zNw-4E!K2RMM==JPp3bjMBPn8W?Ta99?g75vC-tJs/VULP3FMy/e24vp6Q?ikz348nGH99jUF77eN2AVcZdaDcZPrHG44aUs!d27R7T!FA8lk=Qi65/98RE=4qzpsQ?Ml/RvyJrpDHJn-AtKbxj3vYVt6FII?g41ewwV?ItqP?9k0djiKJk5K?H8.j"
    };
    return urls[currentEnv];
};

const PATH_NAME = {
    affiliate: "affiliate",
    marketing: "marketing",
    marketing_v2: "marketing/v2",
    delist: "delist"
};

const strapiSSRApiUrl = () => {
    const urls =  {
        LOCAL: "https://blog-in-cms.qac24svc.dev",
        STAGING: "https://blog-in-cms.qac24svc.dev",
        QA: "http://blog-in-cms.default",
        PRODUCTION: "http://blog-in-cms-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const strapiApiUrl = () => {
    const urls =  {
        LOCAL: "https://blog-in-cms.qac24svc.dev",
        STAGING: "https://blog-in-cms.qac24svc.dev",
        QA: "https://blog-in-cms.qac24svc.dev",
        PRODUCTION: "https://blog-in-cms-prod.cars24.team"
    };
    return urls[currentEnv];
};

const strapiAuthKeys = () => {
    const keys =  {
        LOCAL: "ad3535045503d1e636eeb92f5e9c3d609ae8bc8fe8ab1a81c58d27b460d51a42e03c1bc5f5c039fcdc7fbfeb2bb948063c81197c85008ee3567864c694f885591802e555d6a622ae2cc0eff50b48a286bf02a0dd70de384e8fdbb769de882f099a717a8852d9883f60d9c471715a652558039428c18652740d4e9cec5ec73f2d",
        STAGING: "ad3535045503d1e636eeb92f5e9c3d609ae8bc8fe8ab1a81c58d27b460d51a42e03c1bc5f5c039fcdc7fbfeb2bb948063c81197c85008ee3567864c694f885591802e555d6a622ae2cc0eff50b48a286bf02a0dd70de384e8fdbb769de882f099a717a8852d9883f60d9c471715a652558039428c18652740d4e9cec5ec73f2d",
        QA: "ad3535045503d1e636eeb92f5e9c3d609ae8bc8fe8ab1a81c58d27b460d51a42e03c1bc5f5c039fcdc7fbfeb2bb948063c81197c85008ee3567864c694f885591802e555d6a622ae2cc0eff50b48a286bf02a0dd70de384e8fdbb769de882f099a717a8852d9883f60d9c471715a652558039428c18652740d4e9cec5ec73f2d",
        PRODUCTION: "ad3535045503d1e636eeb92f5e9c3d609ae8bc8fe8ab1a81c58d27b460d51a42e03c1bc5f5c039fcdc7fbfeb2bb948063c81197c85008ee3567864c694f885591802e555d6a622ae2cc0eff50b48a286bf02a0dd70de384e8fdbb769de882f099a717a8852d9883f60d9c471715a652558039428c18652740d4e9cec5ec73f2d"
    };

    return keys[currentEnv];
};

const catalogUserProfile = () => {
    const urls = {
        LOCAL: "https://b2c-user-profile-in.qac24svc.dev",
        STAGING: "https://stage-b2c-user-profile-in.qac24svc.dev",
        QA: "https://b2c-user-profile-in.qac24svc.dev",
        LNOD: "https://b2c-user-profile-in.qac24svc.dev",
        PRODUCTION: "https://b2c-user-profile-in.c24.tech"
    };
    return urls[currentEnv];
};

const superAppSSRUrl = () => {
    const urls = {
        LOCAL: "https://super-app-backend.qac24svc.dev",
        STAGING: "https://stage-super-app-backend.qac24svc.dev",
        QA: "https://super-app-backend.qac24svc.dev",
        LNOD: "https://super-app-backend.qac24svc.dev",
        PRODUCTION: "http://super-app-backend-prod.cars24-service:8080"
    };
    return urls[currentEnv];
};

const superAppUrl = () => {
    const urls = {
        LOCAL: "https://super-app-backend.qac24svc.dev",
        STAGING: "https://stage-super-app-backend.qac24svc.dev",
        QA: "https://super-app-backend.qac24svc.dev",
        LNOD: "https://super-app-backend.qac24svc.dev",
        PRODUCTION: "https://superappapi.cars24.team"
    };
    return urls[currentEnv];
};

const superAppAuthKeys = () => {
    const urls = {
        LOCAL: "bRmPOz?wCnEtvx?rStOyy?HsWZmYhvD6g3zrJBZ6DUc3Q6WvPzuLnM9oF!J5=!B4/uilu/A9zS3B1mI7pgGjG9Z2H3B8YtZrYxJ1jN4w2sZP7B?N2nR4w1R5a",
        STAGING: "bRmPOz?wCnEtvx?rStOyy?HsWZmYhvD6g3zrJBZ6DUc3Q6WvPzuLnM9oF!J5=!B4/uilu/A9zS3B1mI7pgGjG9Z2H3B8YtZrYxJ1jN4w2sZP7B?N2nR4w1R5a",
        QA: "bRmPOz?wCnEtvx?rStOyy?HsWZmYhvD6g3zrJBZ6DUc3Q6WvPzuLnM9oF!J5=!B4/uilu/A9zS3B1mI7pgGjG9Z2H3B8YtZrYxJ1jN4w2sZP7B?N2nR4w1R5a",
        LNOD: "bRmPOz?wCnEtvx?rStOyy?HsWZmYhvD6g3zrJBZ6DUc3Q6WvPzuLnM9oF!J5=!B4/uilu/A9zS3B1mI7pgGjG9Z2H3B8YtZrYxJ1jN4w2sZP7B?N2nR4w1R5a",
        PRODUCTION: "bRmPOz?wCnEtvx?rStOyy?HsWZmYhvD6g3zrJBZ6DUc3Q6WvPzuLnM9oF!J5=!B4/uilu/A9zS3B1mI7pgGjG9Z2H3B8YtZrYxJ1jN4w2sZP7B?N2nR4w1R5a"
    };
    return urls[currentEnv];
};

const b2cGatewayApiUrl = () => {
    const urls = {
        LOCAL: "https://stage-b2c-gateway-india.qac24svc.dev",
        STAGING: "https://stage-b2c-gateway-india.qac24svc.dev",
        QA: "https://stage-b2c-gateway-india.qac24svc.dev",
        LNOD: "https://stage-b2c-gateway-india.qac24svc.dev",
        PRODUCTION: "https://b2c-gateway-india.c24.tech"
    };
    return urls[currentEnv];
};

module.exports = {
    currentEnv,
    appUrl,
    apiUrl,
    baseApiUrl,
    ssrApiUrl,
    getPrivateProductApiKey,
    ppApiUrl,
    c2bAuthKey,
    getLogoURL,
    exchangeApiUrl,
    lmsAuthKey,
    vehicleApiUrl,
    vehicleSSRApiUrl,
    checkoutApiUrl,
    checkoutApiSSRUrl,
    consumerFinanceApiUrl,
    listingApiUrl,
    leadApiUrlKey,
    c2bKeys,
    leadServiceJavaApi,
    getConsumerFinanceApiKey,
    leadServiceJavaSSRApi,
    b2cUserServiceUrl,
    lmsLeadAuthKey,
    carConsumerGatewayApi,
    incidentManagement,
    b2cCrmServiceUrl,
    couponServiceUrl,
    c2cBaseUrl,
    lmsBaseApiUrl,
    PATH_NAME,
    omsAggregatorApi,
    omsAggregatorApiSSRUrl,
    c2cBaseSSRUrl,
    carConsumerGatewaySSRApi,
    getC2bProductApiKey,
    catalogGatewayUrl,
    catalogGatewaySSRUrl,
    getDiyOgImageUrl,
    staticFastlyAppUrl,
    consumerFinanceSSRApiUrl,
    catalogServiceUrl,
    cmsSSRUrl,
    cmsUrl,
    cmsApiKeys,
    strapiSSRApiUrl,
    strapiAuthKeys,
    strapiApiUrl,
    catalogUserProfile,
    superAppSSRUrl,
    superAppUrl,
    superAppAuthKeys,
    newCarsCmsApiKeys,
    vasCmsApiKeys,
    b2cGatewayApiUrl
};
